import { Control, Controller } from "react-hook-form";

import {
  CheckboxGroupField,
  ChipField,
  PhoneField,
  RadioField,
  SelectField,
  SwitchField,
  TextField,
  TextareaField,
} from "./Fields";

export type FieldType =
  | "checkbox"
  | "date"
  | "dropdown"
  | "email"
  | "input"
  | "radio"
  | "phone"
  | "chip"
  | "textarea"
  | "switch"
  | "time";

export interface FieldConfig {
  id?: string;
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  type?: FieldType;
  options?: string[] | { label: string; value: string | number | boolean }[];
  [key: string]: any;
}

const FIELDS = {
  input: TextField,
  date: TextField,
  time: TextField,
  email: TextField,
  phone: PhoneField,
  radio: RadioField,
  dropdown: SelectField,
  checkbox: CheckboxGroupField,
  chip: ChipField,
  textarea: TextareaField,
  switch: SwitchField,
};

type FormFieldControlledProps = {
  field: FieldConfig;
  control: Control<any>;
};

export const FormFieldControlled = ({ field, control }: FormFieldControlledProps) => {
  const Field = FIELDS[field.type ?? "input"];
  const id = field.id ?? field.name;

  return (
    <Controller
      control={control}
      name={field.name}
      render={({ field: fieldData, fieldState: { error } }) => {
        return (
          <div className="flex flex-col gap-2 z-[1500]">
            {field.label && (
              <label className="text-[#3C3C4399] text-sm" htmlFor={field.name}>
                {field.label}
              </label>
            )}
            <Field {...field} {...fieldData} id={id} />

            {Boolean(error) && <span className="text-sm text-red-500">{error?.message}</span>}
          </div>
        );
      }}
    />
  );
};
