"use client";

import { ReactNode, createContext, useContext, useEffect } from "react";

import type { User } from "@/types/user";
import { UserType } from "@prisma/client";
import * as Sentry from "@sentry/nextjs";
import posthog from "posthog-js";

import { getInitialsFromName, getSplitFullName } from "@helpers/profile";
import { getProfilePercentage } from "@helpers/profile-percentage";
import { useProfile } from "@hooks/useProfile";

const isProduction = process.env.NODE_ENV === "production";

export type UserContextType = User & {
  userId: string;
  isAdmin: boolean;
  initials: string;
  lastName: string;
  givenName: string;
  isLoading: boolean;
  profilePercentage: number;
};

export const UserContext = createContext<UserContextType>({} as UserContextType);

const UserProvider = ({ children }: { children: ReactNode }) => {
  const { data, isInitialLoading } = useProfile();

  useEffect(() => {
    if (isProduction && data?.email) {
      const { id, email, name } = data;

      posthog.identify(id, { email, name });
      Sentry.setUser({ id, email, username: name });
    }
  }, [data]);

  const userId = data?.id;
  const initials = getInitialsFromName(data?.name);
  const isAdmin = data?.roles?.includes(UserType.ADMIN);
  const profilePercentage = getProfilePercentage(data);
  const [givenName, lastName] = getSplitFullName(data?.name);

  return (
    <UserContext.Provider
      value={{
        ...data,
        userId,
        isAdmin,
        initials,
        lastName,
        givenName,
        isLoading: isInitialLoading,
        profilePercentage,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;

export const useAuth = () => useContext<UserContextType>(UserContext);
