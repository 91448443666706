import { ReactNode } from "react";

import { cn } from "@libs/utils";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";

import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@components/ui/dialog";

type PopupProps = {
  children: ReactNode;
  onClose: () => void;
  className?: string;
  closeButton?: boolean;
} & ({ title: string; placeholderTitle?: string } | { title?: string; placeholderTitle: string });

const Popup = ({
  onClose,
  children,
  title,
  placeholderTitle,
  className,
  closeButton,
}: PopupProps) => {
  const Title = () => {
    const component = (
      <DialogHeader>
        <DialogTitle className="text-xl font-medium">{title || placeholderTitle}</DialogTitle>
      </DialogHeader>
    );

    if (!title) {
      return <VisuallyHidden asChild>{component}</VisuallyHidden>;
    }

    return component;
  };

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent
        role="dialog"
        closeButton={closeButton}
        onOpenAutoFocus={event => event.preventDefault()}
        className={cn("flex flex-col gap-5 max-w-[1000px] overflow-auto", className)}
      >
        <Title />
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default Popup;
