import { forwardRef } from "react";

import { cn } from "@libs/utils";

import { RadioGroup, RadioGroupItem } from "@components/ui/radio-group";

import { FieldProps, getOptions } from "./utils";

export const RadioField = forwardRef<HTMLInputElement, Partial<FieldProps>>(
  ({ name, options, onChange, onBlur, value, disabled, align = "vertical" }, ref) => {
    const fieldOptions = getOptions(options);

    return (
      <RadioGroup
        onBlur={onBlur}
        disabled={disabled}
        value={value as string}
        onValueChange={onChange}
        className={cn("flex flex-wrap gap-3", {
          "flex-row gap-5": align === "horizontal",
          "flex-col": align === "vertical",
        })}
      >
        {fieldOptions?.map(option => (
          <div className="flex items-center space-x-2" key={`radio_${name}_${option.value}`}>
            <RadioGroupItem value={option.value as any} id={`radio_${name}_${option.value}`} />
            <label htmlFor={`radio_${name}_${option.value}`} className="text-sm text-[#374151]">
              {option.label}
            </label>
          </div>
        ))}
      </RadioGroup>
    );
  }
);
