import { forwardRef } from "react";

import { AutosizeTextarea } from "@components/ui/autosize-textarea";

import { FieldProps } from "./utils";

export const TextareaField = forwardRef<HTMLTextAreaElement, Partial<FieldProps>>(
  (question, ref) => {
    return <AutosizeTextarea {...question} />;
  }
);
