import { forwardRef } from "react";

import { Input } from "@components/ui/input";

import { FieldProps } from "./utils";

export const TextField = forwardRef<HTMLInputElement, FieldProps>(
  ({ name, onChange, onBlur, value, disabled, ...props }, ref) => {
    return (
      <Input
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        disabled={disabled}
        {...props}
      />
    );
  }
);
