import { forwardRef, memo, useCallback } from "react";

import { Badge } from "@/components/ui/badge";

import { FieldProps, getOptions, handleMultiSelect } from "./utils";

const MemoizedBadge = memo(Badge);

export const ChipField = forwardRef<HTMLDivElement, Partial<FieldProps>>(
  ({ name, options, value = [], onChange }, ref) => {
    const fieldOptions = getOptions(options);

    const handleChange = useCallback(
      (selectedOption: string) => {
        handleMultiSelect(value, selectedOption, onChange);
      },
      [value, onChange]
    );

    const handleClick = useCallback(
      (optionValue: string) => () => {
        handleChange(optionValue);
      },
      [handleChange]
    );

    return (
      <div className="flex flex-col md:flex-row flex-wrap gap-3 mt-2">
        {fieldOptions?.map(option => (
          <MemoizedBadge
            size="lg"
            asChild
            key={`chip_${name}_${option.value}`}
            variant={value.includes(option.value) ? "info-light" : "outline"}
            className="focus:ring-0 focus:ring-offset-0 text-sm font-normal text-left"
          >
            <button onClick={handleClick(option.value as string)}>{option.label}</button>
          </MemoizedBadge>
        ))}
      </div>
    );
  }
);
