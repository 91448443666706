import { forwardRef } from "react";

import metadata from "libphonenumber-js/min/metadata";
import ReactPhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

import { FieldProps } from "./utils";

type PhoneFieldProps = Pick<FieldProps, "id" | "value" | "onChange" | "disabled" | "name">;

export const PhoneField = forwardRef(
  ({ value, onChange, disabled, name }: PhoneFieldProps, ref) => {
    return (
      <ReactPhoneInput
        defaultCountry="US"
        displayInitialValueAsLocalNumber
        metadata={metadata}
        flagUrl="https://purecatamphetamine.github.io/country-flag-icons/1x1/{XX}.svg"
        className="border p-3 px-5 rounded-md h-[52px] [&_input]:focus-within:outline-none [&_input]:px-2 text-sm"
        value={value}
        disabled={disabled}
        name={name}
        onChange={value => onChange(value as string)}
      />
    );
  }
);
