import { forwardRef } from "react";

import { Checkbox } from "@components/ui/checkbox";

import { FieldProps } from "./utils";

export const SwitchField = forwardRef<HTMLInputElement, Partial<FieldProps>>(
  ({ name, onChange, value, disabled, optionLabel, label }, ref) => {
    return (
      <div className="flex items-center space-x-2">
        <Checkbox
          name={name}
          checked={value}
          disabled={disabled}
          id={`checkbox_${name}`}
          onCheckedChange={onChange}
        />
        <label htmlFor={`checkbox_${name}`} className="text-sm text-[#374151]">
          {optionLabel ?? label}
        </label>
      </div>
    );
  }
);
