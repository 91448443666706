import { forwardRef, useCallback } from "react";

import { cn } from "@libs/utils";

import { Checkbox } from "@components/ui/checkbox";

import { FieldProps, getOptions, handleMultiSelect } from "./utils";

export const CheckboxGroupField = forwardRef<HTMLDivElement, Partial<FieldProps>>(
  ({ name, options, value = [], onChange, disabled, align = "vertical" }, ref) => {
    const fieldOptions = getOptions(options);

    const handleChange = useCallback(
      (selectedOption: string) => {
        handleMultiSelect(value, selectedOption, onChange);
      },
      [value, onChange]
    );

    const handleClick = useCallback(
      (optionValue: string) => () => {
        handleChange(optionValue);
      },
      [handleChange]
    );

    return (
      <div
        className={cn("flex flex-wrap gap-3", {
          "flex-row gap-5": align === "horizontal",
          "flex-col": align === "vertical",
        })}
      >
        {fieldOptions?.map(option => (
          <div className="flex items-center space-x-2" key={`checkbox_${name}_${option.value}`}>
            <Checkbox
              name={name}
              disabled={disabled}
              checked={value.includes(option.value)}
              id={`checkbox_${name}_${option.value}`}
              onCheckedChange={handleClick(option.value as string)}
            />
            <label htmlFor={`checkbox_${name}_${option.value}`} className="text-sm text-[#374151]">
              {option.label}
            </label>
          </div>
        ))}
      </div>
    );
  }
);
