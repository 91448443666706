"use client";

import { ThemeProvider } from "@emotion/react";
import dynamic from "next/dynamic";
import { PostHogProvider } from "posthog-js/react";
import { ReactNode } from "react";

import { createIDBPersister } from "@/persistors/idb";
import "@/styles/globals.css";
import { UserProvider } from "@auth0/nextjs-auth0/client";
import { AppContextProvider } from "@context/AppContext";
import { PathwayContentProvider } from "@context/PathwayContent";
import PopupProvider from "@context/PopupContext";
import UserProviderContext from "@context/User";
import { initiatePostHog } from "@libs/posthog";
import { Query, QueryCache, QueryClient, defaultShouldDehydrateQuery } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { AppProgressBar as ProgressBar } from "next-nprogress-bar";
import { GoogleAnalytics } from "nextjs-google-analytics";
import posthog from "posthog-js";
import { toast } from "react-toastify";

import { assembleTheme } from "@styles/theme";

const ToasterNotification = dynamic(() => import("@components/common/ToasterNotification"));

initiatePostHog();

const queryClient = new QueryClient({
  defaultOptions: { queries: { cacheTime: 1000 * 60 * 60 * 24 } },
  queryCache: new QueryCache({
    onError: (error: any, query) => {
      if (query.state.data !== undefined) {
        toast.error(`Something went wrong: ${error.message}`);
      }
    },
  }),
});

const persisOptions = {
  persister: createIDBPersister("assemble"),
  dehydrateOptions: {
    shouldDehydrateQuery: (query: Query) => {
      return defaultShouldDehydrateQuery(query) && query?.meta?.cache !== false;
    },
  },
};

export default function Providers({ children }: { children: ReactNode }) {
  return (
    <ThemeProvider theme={assembleTheme}>
      <PersistQueryClientProvider client={queryClient} persistOptions={persisOptions}>
        <UserProvider>
          <UserProviderContext>
            <AppContextProvider>
              <PathwayContentProvider>
                <PostHogProvider client={posthog}>
                  <PopupProvider>
                    <ToasterNotification />
                    <GoogleAnalytics trackPageViews />
                    <ProgressBar color="#fc6d1d" options={{ showSpinner: false }} shallowRouting />
                    {children}
                  </PopupProvider>
                </PostHogProvider>
              </PathwayContentProvider>
            </AppContextProvider>
          </UserProviderContext>
        </UserProvider>
      </PersistQueryClientProvider>
    </ThemeProvider>
  );
}
