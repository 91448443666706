import { forwardRef } from "react";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@components/ui/select";

import { FieldProps, getOptions } from "./utils";

export const SelectField = forwardRef<HTMLDivElement, Partial<FieldProps>>(
  ({ name, options, onChange, value, placeholder = "Select an option", disabled }, ref) => {
    const fieldOptions = getOptions(options);

    return (
      <Select name={name} onValueChange={onChange} value={value} disabled={disabled}>
        <SelectTrigger id={name}>
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>{placeholder}</SelectLabel>
            {fieldOptions?.map(option => (
              <SelectItem key={`select_${name}_${option.value}`} value={option.value as string}>
                {option.label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    );
  }
);
