"use client";

import Image from "next/image";
import { useEffect } from "react";

import "@/styles/globals.css";
import * as Sentry from "@sentry/nextjs";

import { Button } from "@components/ui/button";

import { assemble } from "@configs/features/assemble";

import { frankRuhlLibre, inter } from "@styles/variants/fonts";

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <html lang="en" className={`${inter.variable} ${frankRuhlLibre.variable}`}>
      <body>
        <header className="py-5">
          <div className="container mx-auto">
            <div className="flex justify-between items-center">
              <a href="/">
                <Image src={assemble.logo} alt="Logo Come Assemble" width={135} height={26} />
              </a>
            </div>
          </div>
        </header>

        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-sm text-center">
            <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">
              500
            </h1>
            <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
              Something went wrong.
            </p>
            <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
              Please try again or email{" "}
              <a href="mailto:contact@comeassemble.com" className="underline text-blue-600">
                contact@comeassemble.com
              </a>{" "}
              for support.
            </p>

            <Button onClick={reset} className="mt-8">
              Try again
            </Button>
          </div>
        </div>
      </body>
    </html>
  );
}
