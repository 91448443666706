"use client";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { FormFieldControlled } from "@components/common/Form";
import Popup from "@components/common/Popup";
import SuccessMessagePopup from "@components/common/SuccessMessagePopup";
import { Button } from "@components/ui/button";

import useTransactionalEmail from "@hooks/useTransactionalEmail";
import { TypeSurveyQuestionFields } from "@models/Contentful";

import { FormValues, fields, getMappedFields, validateSchema } from "./helper";

type PopupRequestDemoProps = {
  onClose: () => void;
};

const PopupRequestDemo = ({ onClose }: PopupRequestDemoProps) => {
  const { onSendEmail, showSuccessPage } = useTransactionalEmail();

  const { handleSubmit, control, formState } = useForm<FormValues>({
    resolver: yupResolver(validateSchema),
    defaultValues: fields.map(({ name }) => ({ [name]: "" })),
  });

  const onSubmit = async (values: FormValues) => {
    try {
      const content = getMappedFields(values);

      await onSendEmail({
        content,
        from: { email: values.email, name: values.firstName },
        subject: "Request a demo - New register",
      });
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <Popup onClose={onClose} title="Request a demo" className="max-w-[800px]">
      {showSuccessPage ? (
        <SuccessMessagePopup onClose={onClose} />
      ) : (
        <>
          <form className="grid grid-cols-2 gap-4 mb-5">
            {fields.map(({ name, fieldType, label, ...rest }) => (
              <FormFieldControlled
                key={name}
                control={control}
                field={{
                  id: name,
                  name,
                  label,
                  type: fieldType as TypeSurveyQuestionFields["type"],
                  ...rest,
                }}
              />
            ))}
          </form>

          <Button
            className="ml-auto"
            isLoading={formState.isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            Request a demo
          </Button>
        </>
      )}
    </Popup>
  );
};

export default PopupRequestDemo;
