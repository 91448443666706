import type { ElementType } from "react";

import AskAssembleIcon from "public/images/menu/ask-assemble.svg";
import AssembleUIcon from "public/images/menu/assemble-u.svg";

export type UserMenuItem = {
  href: string;
  label: string;
  onClick?: () => void;
};

export const userMenu: UserMenuItem[] = [
  { href: "/admin", label: "Admin" },
  { href: "/leader/dashboard", label: "Leader Dashboard" },
  { href: "/sw/dashboard", label: "SW Dashboard" },
  { href: "/profile", label: "Profile" },
  { href: "/standard-work/shifts", label: "Switch to Standard Work" },
  { href: "/api/auth/logout", label: "Logout" },
];

export type LoggedInMenuItem = {
  name: string;
  path: string;
  icon: ElementType;
};

export const loggedInMenu: LoggedInMenuItem[] = [
  { icon: AssembleUIcon, name: "My Pathway", path: "/pathway" },
  { icon: AskAssembleIcon, name: "Ask Assemble", path: "/ask-assemble" },
];
