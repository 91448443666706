import { ChangeEvent, FocusEvent } from "react";

import { FieldConfig } from "../";

export type FieldProps = FieldConfig & {
  onChange: (event: ChangeEvent<HTMLElement> | string | string[]) => void;
  onBlur: (event: FocusEvent<HTMLElement>) => void;
  value: any;
};

export function getOptions(options: FieldConfig["options"]) {
  return options?.map(option => {
    const isObject = typeof option === "object";

    return {
      value: isObject ? option.value : option,
      label: isObject ? option.label : option,
    };
  });
}

export function handleMultiSelect(
  value: string[],
  selectedOption: string,
  onChange: (value: string[]) => void
) {
  if (value.includes(selectedOption)) {
    return onChange(value.filter(item => item !== selectedOption));
  }

  onChange([...value, selectedOption]);
}
